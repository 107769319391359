import { reactive, unref } from 'vue';
import { useQuery, useMutation } from 'vue-query';

import {
  getCooperation,
  getCooperations,
  GetCooperationRequestOptions,
  deleteCooperation,
  DeleteCooperationRequestOptions,
  SolveCooperationRequestOptions,
  solveCooperation,
  ArchiveCooperationRequestOptions,
  archiveCooperation
} from '@/services/api';
import { UseCooperationsOptions } from './interfaces';

export function useCooperation(options: GetCooperationRequestOptions) {
  return useQuery('cooperation', () => getCooperation(options));
}

export function useCooperations({ page, keyword, status, archived, sortBy, descSortBy }: UseCooperationsOptions = {}) {
  return useQuery(
    reactive(['cooperations', { page, keyword, status, archived, sortBy, descSortBy }]),
    () => getCooperations({ query: { page: unref(page), keyword: unref(keyword), status: unref(status), archived: unref(archived), sortBy: unref(sortBy), descSortBy: unref(descSortBy) } }),
    { keepPreviousData: true }
  );
}

export function useDeleteCooperation() {
  return useMutation((options: DeleteCooperationRequestOptions) => deleteCooperation(options));
}

export function useSolveCooperation() {
  return useMutation((options: SolveCooperationRequestOptions) => solveCooperation(options));
}

export function useArchiveCooperation() {
  return useMutation((options: ArchiveCooperationRequestOptions) => archiveCooperation(options));
}
