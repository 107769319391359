import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getModel,
  getModels,
  createModel,
  updateModel,
  GetModelRequestOptions,
  CreateModelRequestOptions,
  UpdateModelRequestOptions,
  DeleteModelRequestOptions,
  deleteModel,
  ModelResponse
} from '@/services/api';
import { UseModelsOptions } from './interfaces';

export function useModel(options: GetModelRequestOptions, useOptions: Omit<UseQueryOptions<ModelResponse, unknown, ModelResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('model', () => getModel(options), useOptions);
}

export function useModels({ page, keyword, gender, isPopular, sortBy, descSortBy }: UseModelsOptions = {}) {
  return useQuery(
    reactive(['models', { page, keyword, gender, isPopular, sortBy, descSortBy }]),
    () => getModels({ query: { page: unref(page), keyword: unref(keyword), gender: unref(gender), isPopular: unref(isPopular), sortBy: unref(sortBy), descSortBy: unref(descSortBy) } }),
    { keepPreviousData: true }
  );
}

export function useCreateModel() {
  return useMutation((options: CreateModelRequestOptions) => createModel(options));
}

export function useUpdateModel() {
  return useMutation((options: UpdateModelRequestOptions) => updateModel(options));
}

export function useDeleteModel() {
  return useMutation((options: DeleteModelRequestOptions) => deleteModel(options));
}
