import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getInformation,
  getInformations,
  createInformation,
  updateInformation,
  GetInformationRequestOptions,
  CreateInformationRequestOptions,
  UpdateInformationRequestOptions,
  InformationResponse
} from '@/services/api';
import { UseInformationsOptions } from './interfaces';

export function useInformation(options: GetInformationRequestOptions, useOptions: Omit<UseQueryOptions<InformationResponse, unknown, InformationResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('information', () => getInformation(options), useOptions);
}

export function useInformations({ page }: UseInformationsOptions = {}) {
  return useQuery(
    reactive(['informations', { page }]),
    () => getInformations({ query: { page: unref(page) } }),
    { keepPreviousData: true }
  );
}

export function useCreateInformation() {
  return useMutation((options: CreateInformationRequestOptions) => createInformation(options));
}

export function useUpdateInformation() {
  return useMutation((options: UpdateInformationRequestOptions) => updateInformation(options));
}
