import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getOrders,
  GetOrderRequestOptions,
  OrderResponse,
  getOrder,
  UpdateOrderRequestOptions,
  updateOrder
} from '@/services/api';
import { UseOrdersOptions } from './interfaces';

export function useOrder(options: GetOrderRequestOptions, useOptions: Omit<UseQueryOptions<OrderResponse, unknown, OrderResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('Order', () => getOrder(options), useOptions);
}

export function useOrders({ page, keyword, sortBy, descSortBy }: UseOrdersOptions = {}) {
  return useQuery(
    reactive(['orders', { page, keyword, sortBy, descSortBy }]),
    () => getOrders({ query: { page: unref(page), sortBy: unref(sortBy), descSortBy: unref(descSortBy), keyword: unref(keyword) } }),
    { keepPreviousData: true }
  );
}

export function useUpdateOrder() {
  return useMutation((options: UpdateOrderRequestOptions) => updateOrder(options));
}
