import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getDeal,
  getDeals,
  createDeal,
  updateDeal,
  GetDealRequestOptions,
  CreateDealRequestOptions,
  UpdateDealRequestOptions,
  DeleteDealRequestOptions,
  deleteDeal,
  DealResponse
} from '@/services/api';
import { UseDealsOptions } from './interfaces';

export function useDeal(options: GetDealRequestOptions, useOptions: Omit<UseQueryOptions<DealResponse, unknown, DealResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('deal', () => getDeal(options), useOptions);
}

export function useDeals({ page, sortBy, descSortBy }: UseDealsOptions = {}) {
  return useQuery(
    reactive(['deals', { page, sortBy, descSortBy }]),
    () => getDeals({ query: { page: unref(page), descSortBy: unref(descSortBy), sortBy: unref(sortBy) } }),
    { keepPreviousData: true }
  );
}

export function useCreateDeal() {
  return useMutation((options: CreateDealRequestOptions) => createDeal(options));
}

export function useUpdateDeal() {
  return useMutation((options: UpdateDealRequestOptions) => updateDeal(options));
}

export function useDeleteDeal() {
  return useMutation((options: DeleteDealRequestOptions) => deleteDeal(options));
}
