import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getIpWhitelist,
  getIpWhitelists,
  createIPAdress,
  updateIpAddress,
  deleteIpAddress,
  GetIpWhitelistRequestOptions,
  CreateIPAdressRequestOptions,
  UpdateIpAddressRequestOptions,
  DeleteIpAddressRequestOptions,
  IpWhitelistResponse
} from '@/services/api';
import { UseIpWhitelistsOptions } from './interfaces';

export function useWhitelist(options: GetIpWhitelistRequestOptions, useOptions: Omit<UseQueryOptions<IpWhitelistResponse, unknown, IpWhitelistResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('whitelist', () => getIpWhitelist(options), useOptions);
}

export function useWhitelists({ page, keyword }: UseIpWhitelistsOptions = {}) {
  return useQuery(
    reactive(['whitelists', { page, keyword }]),
    () => getIpWhitelists({ query: { page: unref(page), keyword: unref(keyword) } }),
    { keepPreviousData: true }
  );
}

export function useCreateIpAddress() {
  return useMutation((options: CreateIPAdressRequestOptions) => createIPAdress(options));
}

export function useUpdateIpAddress() {
  return useMutation((options: UpdateIpAddressRequestOptions) => updateIpAddress(options));
}

export function useDeleteIpAddress() {
  return useMutation((options: DeleteIpAddressRequestOptions) => deleteIpAddress(options));
}
