import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getVideo,
  getVideos,
  createVideo,
  updateVideo,
  deleteVideo,
  publishVideo,
  GetVideoRequestOptions,
  CreateVideoRequestOptions,
  UpdateVideoRequestOptions,
  DeleteVideoRequestOptions,
  PublishVideoRequestOptions,
  VideoResponse
} from '@/services/api';
import { UseVideosOptions } from './interfaces';

export function useVideo(options: GetVideoRequestOptions, useOptions: Omit<UseQueryOptions<VideoResponse, unknown, VideoResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('video', () => getVideo(options), useOptions);
}

export function useVideos({ page, status, keyword, isFree, sortBy, descSortBy }: UseVideosOptions = {}) {
  return useQuery(
    reactive(['videos', { page, status, keyword, isFree, sortBy, descSortBy }]),
    () => getVideos({ query: { page: unref(page), status: unref(status), keyword: unref(keyword), isFree: unref(isFree), sortBy: unref(sortBy), descSortBy: unref(descSortBy) } }),
    { keepPreviousData: true }
  );
}

export function useCreateVideo() {
  return useMutation((options: CreateVideoRequestOptions) => createVideo(options));
}

export function useUpdateVideo() {
  return useMutation((options: UpdateVideoRequestOptions) => updateVideo(options));
}

export function useDeleteVideo() {
  return useMutation((options: DeleteVideoRequestOptions) => deleteVideo(options));
}

export function usePublishVideo() {
  return useMutation((options: PublishVideoRequestOptions) => publishVideo(options));
}
