import { reactive, unref } from 'vue';
import { useQuery } from 'vue-query';

import { getPermissions } from '@/services/api';

export function usePermissions() {
  return useQuery(
    reactive(['permissions']),
    () => getPermissions(),
    { keepPreviousData: true }
  );
}
