import { reactive, unref } from 'vue';
import { useQuery } from 'vue-query';

import {
  getSubscriptions
} from '@/services/api';
import { UseSubscriptionsOptions } from './interfaces';

export function useSubscriptions({ page, keyword, planId }: UseSubscriptionsOptions = {}) {
  return useQuery(
    reactive(['subscriptions', { page, keyword, planId }]),
    () => getSubscriptions({ query: { page: unref(page), keyword: unref(keyword), planId: unref(planId) } }),
    { keepPreviousData: true }
  );
}
