import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getBanner,
  getBanners,
  createBanner,
  updateBanner,
  GetBannerRequestOptions,
  CreateBannerRequestOptions,
  UpdateBannerRequestOptions,
  DeleteBannerRequestOptions,
  deleteBanner,
  BannerResponse
} from '@/services/api';
import { UseBannersOptions } from './interfaces';

export function useBanner(options: GetBannerRequestOptions, useOptions: Omit<UseQueryOptions<BannerResponse, unknown, BannerResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('banner', () => getBanner(options), useOptions);
}

export function useBanners({ page, keyword, type, sortBy, descSortBy, position, isCta }: UseBannersOptions = {}) {
  return useQuery(
    reactive(['banners', { page, keyword, type, sortBy, descSortBy, position, isCta }]),
    () => getBanners({ query: { page: unref(page), keyword: unref(keyword), type: unref(type), sortBy: unref(sortBy), descSortBy: unref(descSortBy), position: unref(position), isCta: unref(isCta) } }),
    { keepPreviousData: true }
  );
}

export function useCreateBanner() {
  return useMutation((options: CreateBannerRequestOptions) => createBanner(options));
}

export function useUpdateBanner() {
  return useMutation((options: UpdateBannerRequestOptions) => updateBanner(options));
}

export function useDeleteBanner() {
  return useMutation((options: DeleteBannerRequestOptions) => deleteBanner(options));
}
