import { provide, reactive, unref } from 'vue';
import { useQuery, useMutation } from 'vue-query';

import {
  getDailyData
} from '@/services/api';
import { UseDailyDataOptions } from './interfaces';

export function useDailyData({ page, date }: UseDailyDataOptions = {}) {
  return useQuery(
    reactive(['dailyData', { page, date }]),
    () => getDailyData({
      query: {
        page: unref(page),
        ...(unref(date).length > 0 && {
          date: `${unref(date)[0]} ${unref(date)[1]}`
        })
      }
    }),
    { keepPreviousData: true }
  );
}
