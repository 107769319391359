import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getPost,
  getPosts,
  createPost,
  updatePost,
  GetPostRequestOptions,
  CreatePostRequestOptions,
  UpdatePostRequestOptions,
  deletePost,
  DeletePostRequestOptions,
  PostResponse
} from '@/services/api';
import { UsePostsOptions } from './interfaces';

export function usePost(options: GetPostRequestOptions, useOptions: Omit<UseQueryOptions<PostResponse, unknown, PostResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('post', () => getPost(options), useOptions);
}

export function usePosts({ page, keyword, status, isSticky, sortBy, descSortBy }: UsePostsOptions = {}) {
  return useQuery(
    reactive(['posts', { page, keyword, status, isSticky, sortBy, descSortBy }]),
    () => getPosts({ query: { page: unref(page), keyword: unref(keyword), status: unref(status), isSticky: unref(isSticky), sortBy: unref(sortBy), descSortBy: unref(descSortBy) } }),
    { keepPreviousData: true }
  );
}

export function useCreatePost() {
  return useMutation((options: CreatePostRequestOptions) => createPost(options));
}

export function useUpdatePost() {
  return useMutation((options: UpdatePostRequestOptions) => updatePost(options));
}

export function useDeletePost() {
  return useMutation((options: DeletePostRequestOptions) => deletePost(options));
}
