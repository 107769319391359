import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getSite,
  getSites,
  createSite,
  updateSite,
  GetSiteRequestOptions,
  CreateSiteRequestOptions,
  UpdateSiteRequestOptions,
  DeleteSiteRequestOptions,
  deleteSite,
  SiteResponse
} from '@/services/api';
import { UseSitesOptions } from './interfaces';

export function useSite(options: GetSiteRequestOptions, useOptions: Omit<UseQueryOptions<SiteResponse, unknown, SiteResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('site', () => getSite(options), useOptions);
}

export function useSites({ page, keyword, startDate, endDate, sortBy, descSortBy }: UseSitesOptions = {}) {
  return useQuery(
    reactive(['sites', { page, keyword, startDate, endDate, sortBy, descSortBy }]),
    () => getSites({ query: { page: unref(page), keyword: unref(keyword), startDate: unref(startDate), endDate: unref(endDate), sortBy: unref(sortBy), descSortBy: unref(descSortBy) } }),
    { keepPreviousData: true }
  );
}

export function useCreateSite() {
  return useMutation((options: CreateSiteRequestOptions) => createSite(options));
}

export function useUpdateSite() {
  return useMutation((options: UpdateSiteRequestOptions) => updateSite(options));
}

export function useDeleteSite() {
  return useMutation((options: DeleteSiteRequestOptions) => deleteSite(options));
}
