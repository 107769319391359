
import { defineComponent, watch, ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useAdvertisement } from '@/composables/api';
import { useI18n } from 'vue-i18n';


export default defineComponent({
  setup() {
    const advertisementsId = useRoute().params.id as string;
    const page = useRoute().query.page;
    const advertisement = ref();
    const { t } = useI18n();

    const ctaPositionDescriptions = {
      0: 'No display',
      1: 'Top Left',
      2: 'Top Center',
      3: 'Top Right',
      4: 'Middle Left',
      5: 'Middle Center',
      6: 'Middle Right',
      7: 'Bottom Left',
      8: 'Bottom Center',
      9: 'Bottom Right'
    };

    const { data, dataUpdatedAt } = useAdvertisement({ advertisementsId });

    watch(dataUpdatedAt, () => {
      advertisement.value = data.value.data;
    });

    const ctaPosDescription = computed(() => {
      const ctaPosition = advertisement.value?.ctaPosition;
      return ctaPositionDescriptions[ctaPosition] || '';
    });

    return {
      page,
      advertisement,
      ctaPosDescription,
      t
    };
  }
});
