import { reactive, unref } from 'vue';
import { useQuery, useMutation } from 'vue-query';

import {
  getSupport,
  getSupports,
  GetSupportRequestOptions,
  deleteSupport,
  DeleteSupportRequestOptions,
  SolveSupportRequestOptions,
  solveSupport,
  ArchiveSupportRequestOptions,
  archiveSupport,
  recoverSupport,
  RecoverSupportRequestOptions
} from '@/services/api';
import { UseSupportsOptions } from './interfaces';

export function useSupport(options: GetSupportRequestOptions) {
  return useQuery('support', () => getSupport(options));
}

export function useSupports({ page, keyword, status, isArchived, sortBy, descSortBy }: UseSupportsOptions = {}) {
  return useQuery(
    reactive(['supports', { page, keyword, status, isArchived, sortBy, descSortBy }]),
    () => getSupports({ query: { page: unref(page), keyword: unref(keyword), status: unref(status), isArchived: unref(isArchived), sortBy: unref(sortBy), descSortBy: unref(descSortBy) } }),
    { keepPreviousData: true }
  );
}

export function useDeleteSupport() {
  return useMutation((options: DeleteSupportRequestOptions) => deleteSupport(options));
}

export function useSolveSupport() {
  return useMutation((options: SolveSupportRequestOptions) => solveSupport(options));
}

export function useArchiveSupport() {
  return useMutation((options: ArchiveSupportRequestOptions) => archiveSupport(options));
}

export function useRecoverSupport() {
  return useMutation((options: RecoverSupportRequestOptions) => recoverSupport(options));
}
