import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';

import {
  getAdmin,
  getAdmins,
  createAdmin,
  updateAdmin,
  deleteAdmin,
  enableAdmin,
  disableAdmin,
  GetAdminRequestOptions,
  CreateAdminRequestOptions,
  UpdateAdminRequestOptions,
  DeleteAdminRequestOptions,
  ResetAdminRequestOptions,
  resetQRCodeAdmin,
  DisableAdminRequestOptions,
  EnableAdminRequestOptions,
  AdminResponse
} from '@/services/api';
import { UseAdminsOptions } from './interfaces';

export function useAdmin(options: GetAdminRequestOptions, useOptions: Omit<UseQueryOptions<AdminResponse, unknown, AdminResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('admin', () => getAdmin(options), useOptions);
}

export function useAdmins({ page, status, keyword, sortBy, descSortBy }: UseAdminsOptions = {}) {
  return useQuery(
    reactive(['admins', { page, status, keyword, sortBy, descSortBy }]),
    () => getAdmins({ query: { page: unref(page), status: unref(status), keyword: unref(keyword), sortBy: unref(sortBy), descSortBy: unref(descSortBy) } }),
    { keepPreviousData: true }
  );
}

export function useCreateAdmin() {
  return useMutation((options: CreateAdminRequestOptions) => createAdmin(options));
}

export function useUpdateAdmin() {
  return useMutation((options: UpdateAdminRequestOptions) => updateAdmin(options));
}

export function useDeleteAdmin() {
  return useMutation((options: DeleteAdminRequestOptions) => deleteAdmin(options));
}

export function useResetQRCodeAdmin() {
  return useMutation((options: ResetAdminRequestOptions) => resetQRCodeAdmin(options));
}

export function useEnableAdmin() {
  return useMutation((options: EnableAdminRequestOptions) => enableAdmin(options));
}

export function useDisableAdmin() {
  return useMutation((options: DisableAdminRequestOptions) => disableAdmin(options));
}
