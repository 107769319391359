import { reactive, unref } from 'vue';
import { useQuery, useMutation } from 'vue-query';

import {
  getAdvertisement,
  getAdvertisements,
  updateAdvertisement,
  GetAdvertisementRequestOptions,
  UpdateAdvertisementRequestOptions
} from '@/services/api';
import { UseAdvertisementsOptions } from './interfaces';

export function useAdvertisement(options: GetAdvertisementRequestOptions) {
  return useQuery('advertisement', () => getAdvertisement(options));
}

export function useAdvertisements({ page, sortBy, descSortBy }: UseAdvertisementsOptions = {}) {
  return useQuery(
    reactive(['advertisements', { page, sortBy, descSortBy }]),
    () => getAdvertisements({ query: { page: unref(page), sortBy: unref(sortBy), descSortBy: unref(descSortBy) } }),
    { keepPreviousData: true }
  );
}

export function useUpdateAdvertisement() {
  return useMutation((options: UpdateAdvertisementRequestOptions) => updateAdvertisement(options));
}
